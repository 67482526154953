<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import QuestionComponent from "./Question.svelte";
    import Response from "./Response.svelte";
    import ReteynerMastery from "./ReteynerMastery.svelte";
    import { QuizStats } from "./QuizStats";
    import { Question } from "./Question";
    import { Test } from "../question-picker";

    const dispatch = createEventDispatcher<{answer: Question["answers"][number]}>();

    export let question: Question;
    export let quizStats: QuizStats;
    export let test: Test;
    export let fact: string;

</script>


<div class="full-height my-4 w-full flex flex-col gap-4">
    <div class="w-full px-4">
        <ReteynerMastery {question} {quizStats} {test}/>
    </div>
    <div class="full-height flex flex-col justify-center items-center w-full">
        {#if test.submission}
            {@const submission = question.answers.find(a => a.id === test.submission.answer.id)}
            {#if submission}
                <div class="full-height flex flex-col justify-center items-center py-4 w-full gap-4">
                    <Response {submission} {quizStats} {fact}/>
                </div>
            {/if}
        {:else}
            <QuestionComponent {question} on:answer={event => dispatch("answer", event.detail)}/>
        {/if}
    </div>
    
</div>
