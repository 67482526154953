export const funFacts = [
  "Honey never spoils. Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3000 years old and still edible.",
  "Octopuses have three hearts. Two pump blood to the gills, while the third pumps it to the rest of the body.",
  "A day on Venus is longer than a year. Venus takes 243 Earth days to complete a rotation but only 225 Earth days to orbit the sun.",
  "Bananas are berries, but strawberries aren't. Botanically, a berry is a fruit produced from the ovary of a single flower with seeds embedded in the flesh.",
  "The Eiffel Tower can be 15 cm taller during the summer.",
  "Walter Morrison invented the Frisbee in the 1950s, and his ashes were turned into a Frisbee after his death.",
  "Wombat poop is cube-shaped. This helps it not roll away, marking its territory and attracting mates.",
  "The shortest war in history was between Britain and Zanzibar on August 27, 1896. Zanzibar surrendered after 38 minutes.",
  "The Twitter bird's official name is Larry. Named after Larry Bird, the famous basketball player.",
  `A group of flamingos is called a "flamboyance."`,
  "The unicorn is the national animal of Scotland, symbolizing purity and strength in Celtic mythology.",
  "Cows have best friends and can become stressed if they are separated.",
  "The longest time between two twins being born is 90 days.",
  "There are about 20 quadrillion (million billion) ants in the world.",
  "A sneeze travels about 100 miles per hour and can send 100,000 germs into the air.",
  "A large storm cloud can weigh 1 million tonnes.",
  "The average person walks the equivalent of three times around the world in their lifetime.",
  "Starfish don't have brains.",
  "An octopus has nine brains – one central brain and eight in its arms.",
  "A single lightning bolt unleashes enough energy to power a small town for an entire day.",
  "A group of owls is called a parliament.",
  "Dolphins sleep with one eye open.",
  "It's impossible to hum while holding your nose.",
  "The first alarm clock could only ring at 4 a.m.",
  "Birds don't urinate.",
  `The "hashtag" symbol is technically called an octothorp.`,
  "A bolt of lightning is about 5 times hotter than the surface of the sun.",
  "It takes about 8 minutes for sunlight to reach Earth.",
  "Some cats are allergic to humans.",
  "A group of crows is called a murder.",
  `The longest English word without a vowel is "rhythms."`,
  "You can't breathe and swallow at the same time.",
  "The Canary Islands are named after dogs, not birds.",
  "A cat has 32 muscles in each ear.",
  "A crocodile cannot stick its tongue out.",
  "Butterflies taste with their feet.",
  `The dot over the letter "i" and "j" is called a tittle.`,
  `A rhinoceros' horn is made of hair.`,
  `A shrimp's heart is in its head.`,
  `The first product to have a barcode was Wrigley's gum.`,
  "The electric chair was invented by a dentist.",
  `The human brain takes up 20% of your body's energy despite being only 2% of your body's weight.`,
  `It's illegal to own just one guinea pig in Switzerland because they get lonely.`,
  `Venus is the only planet that rotates clockwise.`,
  `In France, it's legal to marry a dead person.`,
  `There are more possible iterations of a game of chess than there are atoms in the known universe.`,
  `Honeybees can recognize human faces.`,
  `The FDA allows 60 insect pieces per 100 grams of chocolate.`,
  `Sea otters hold hands when they sleep so they don't drift away from each other.`,
  `A group of frogs is called an army.`,
  `A group of kangaroos is called a mob.`,
  `An ostrich's eye is bigger than its brain.`,
  `Slugs have four noses.`,
  `The placement of a donkey's eyes in its head enables it to see all four feet at all times.`,
  `Hummingbirds are the only birds that can fly backward.`,
  `An ostrich can run faster than a horse.`,
  `Babies are born with 300 bones, but by adulthood, the number is reduced to 206.`,
  `A ten-gallon hat will only hold ¾ of a gallon.`,
  `"Dreamt" is the only English word that ends in the letters "mt".`,
  `A cat has five toes on its front paws, but only four toes on its back paws.`,
  `Dolphins have names for each other.`,
  `In 1386, a pig in France was executed by public hanging for the murder of a child.`,
  `A group of unicorns is called a blessing.`,
  `It takes a sloth about one month to fully digest a single meal.`,
  `A cow gives nearly 200,000 glasses of milk in a lifetime.`,
  "Mount Everest grows about 4 millimeters every year.",
  "The Sahara Desert can reach temperatures of up to 136\u00b0F (58\u00b0C).",
  "There\u2019s a town in Norway called Hell, and it freezes over every winter.",
  "The Dead Sea is so salty that people can float on it without sinking.",
  "Angel Falls in Venezuela is the world\u2019s highest uninterrupted waterfall.",
  "Antarctica is the driest, windiest, and coldest continent on Earth.",
  "The Great Wall of China is not a single wall but a series of walls built over centuries.",
  "The Amazon River is the largest river by discharge volume of water in the world.",
  "Uluru in Australia changes color throughout the day, glowing red at sunset.",
  "The Blue Lagoon in Iceland is a man-made geothermal spa.",
  "The Grand Canyon is over 277 miles long.",
  "The Atacama Desert in Chile is the driest desert in the world.",
  "Yellowstone National Park was the first national park in the world.",
  "There is a place in Canada called Dildo.",
  "Finland is home to the most saunas per capita in the world.",
  "Lake Baikal in Russia is the deepest and oldest freshwater lake on Earth.",
  "The city of Venice in Italy is built on 118 small islands.",
  "Mount Kilimanjaro in Tanzania is the highest free-standing mountain in the world.",
  "Niagara Falls straddles the border between Canada and the United States.",
  "The Maldives is the lowest-lying country on Earth.",
  "New Zealand is the first country to see the sunrise each day.",
  "Bora Bora is surrounded by a lagoon and a barrier reef.",
  "Madagascar is home to unique wildlife, with 90% of its species found nowhere else.",
  "The Mariana Trench is the deepest part of the world\u2019s oceans.",
  "Santorini in Greece was formed by a volcanic eruption.",
  "Tokyo is the most populous metropolitan area in the world.",
  "The Leaning Tower of Pisa took nearly 200 years to build.",
  "Mont Saint-Michel in France becomes an island at high tide.",
  "Greenland is the largest island in the world.",
  "Iceland is home to Europe\u2019s largest glacier, Vatnaj\u00f6kull.",
  "The Colosseum in Rome could hold up to 80,000 spectators in ancient times.",
  "The Great Barrier Reef is the largest coral reef system in the world.",
  "Mount Fuji is an active volcano and the highest mountain in Japan.",
  "Petra in Jordan is also known as the \u201cRose City\u201d because of the color of its stone.",
  "Stonehenge is a prehistoric monument in England believed to be over 5,000 years old.",
  "Scotland has more than 790 islands.",
  "Bali in Indonesia is known as the Island of the Gods.",
  "The Palace of Versailles in France has over 2,300 rooms.",
  "The city of San Francisco is built on more than 50 hills.",
  "The Maldives has over 1,000 coral islands.",
  "Lake Titicaca, located on the border of Peru and Bolivia, is the highest navigable lake in the world.",
  "Napoleon Bonaparte was once attacked by a horde of bunnies during a hunting trip.",
  "Albert Einstein\u2019s brain was removed and preserved for study after his death.",
  "George Washington grew hemp at Mount Vernon.",
  "Cleopatra lived closer in time to the moon landing than to the construction of the Great Pyramid of Giza.",
  "Thomas Edison\u2019s last breath is reportedly contained in a test tube at the Henry Ford Museum.",
  "Beethoven would pour cold water over his head to stimulate creativity.",
  "Leonardo da Vinci was ambidextrous and could write with both hands simultaneously.",
  "Queen Victoria survived eight assassination attempts.",
  "Mark Twain was born and died during Halley\u2019s Comet's appearances.",
  "Marie Curie\u2019s notebooks are still radioactive.",
  "Abraham Lincoln was a skilled wrestler and was inducted into the National Wrestling Hall of Fame.",
  "Charles Darwin married his first cousin, Emma Wedgwood.",
  "Winston Churchill was hit by a car in New York City and survived.",
  "Vincent van Gogh only sold one painting during his lifetime.",
  "When Julius Caesar was kidnapped by pirates he made them demand a larger ransom as he felt he was worth more than they initially asked for.",
  "Pablo Picasso was given the name Pablo Diego Jos\u00e9 Francisco de Paula Juan Nepomuceno Crisp\u00edn Crispiniano Mar\u00eda Remedios de la Sant\u00edsima Trinidad Ruiz Picasso.",
  "Alexander the Great named over 70 cities after himself.",
  "Harriet Tubman was the first woman to lead an armed assault during the American Civil War.",
  "Benjamin Franklin invented the glass armonica, a musical instrument.",
  "Joan of Arc was only 19 years old when she was executed.",
  "Elvis Presley was a black belt in karate.",
  "Genghis Khan is said to have fathered so many children that 1 in 200 men today may be his descendant.",
  "Theodore Roosevelt was blind in one eye due to a boxing injury.",
  "Frida Kahlo arrived at her first solo art exhibition by ambulance due to her poor health.",
  "Ernest Hemingway survived two plane crashes in two days.",
  "Salvador Dal\u00ed designed the Chupa Chups logo.",
  "Florence Nightingale was an expert statistician and used data visualization in her reports.",
  "The famous pirate Blackbeard once lit slow-burning fuses in his beard to appear more terrifying.",
  "Alfred Hitchcock was afraid of eggs.",
  "Edgar Allan Poe wrote a cryptic letter predicting his death shortly before it happened.",
  "Henry Ford made a car out of soybean-based plastic.",
  "Mozart composed his first symphony at age eight.",
  "Roald Dahl was a fighter pilot and later a spy during World War II.",
  "Che Guevara was a trained medical doctor.",
  "King Tutankhamun's dagger was made from meteoric iron.",
  "Albert Einstein was offered the presidency of Israel in 1952 but declined.",
  "Rasputin survived being poisoned, shot, and beaten before finally drowning.",
  "Leonardo da Vinci invented a robot knight that could sit, wave its arms, and move its head.",
  "Christopher Columbus thought he had reached Asia when he landed in the Americas.",
  "Helen Keller was the first deaf-blind person to earn a Bachelor of Arts degree.",
  "Water can boil and freeze at the same time, a phenomenon known as the triple point.",
  "The mantis shrimp can punch with a speed of 50 mph, creating a small implosion underwater.",
  "Saturn's moon Titan has a dense atmosphere and lakes of liquid methane.",
  "A cockroach can live for weeks without its head before eventually dying of thirst.",
  "Your stomach gets a new lining every 3 to 4 days to prevent it from digesting itself.",
  "Humans share about 60% of their DNA with bananas.",
  "Jupiter\u2019s Great Red Spot is a storm that has been raging for at least 350 years.",
  "Sharks existed before trees; they have been around for over 400 million years.",
  "Neutron stars are so dense that a sugar-cube-sized amount of their material would weigh about a billion tons.",
  "The loudest sound ever recorded on Earth was the Krakatoa volcanic eruption in 1883.",
  "A blue whale\u2019s heart weighs about as much as a small car.",
  "Hot water can freeze faster than cold water under certain conditions, known as the Mpemba effect.",
  "Earth is the only planet not named after a mythological god.",
  "You can fit about 1.3 million Earths into the Sun.",
  "Bats are the only mammals capable of sustained flight.",
  "The fingerprints of a koala bear are so indistinguishable from humans that they can taint crime scenes.",
  "Lightning strikes the Earth about 8 million times a day.",
  "There are more stars in the universe than grains of sand on all the Earth's beaches.",
  "The largest living organism is a fungus in Oregon that covers over 2,385 acres.",
  "The Turritopsis dohrnii jellyfish can revert to its juvenile form, potentially living forever.",
  "The strongest muscle in the human body relative to its size is the masseter, or jaw muscle.",
  "Some species of fish can change their gender as they grow.",
  "There is a species of mushroom that can glow in the dark.",
  "A human\u2019s DNA is 99.9% identical to every other human being.",
  "The Moon is slowly moving away from Earth at a rate of about 3.8 cm per year.",
  "Some metals, like gallium, can melt in your hand.",
  "There are more bacteria in your mouth than there are people on Earth.",
  "An adult human body is made up of approximately 37.2 trillion cells.",
  "The world's smallest reptile, Brookesia nana, is only about the size of a seed.",
  "A jellyfish is about 95% water.",
  "Some frogs can be frozen solid and then thawed and still survive.",
  "The fastest recorded speed of a sneeze is 120 mph.",
  "A day on Uranus lasts 17 hours, 14 minutes, but its seasons last 21 years.",
  'The "smell of rain" is caused by a bacteria called actinomycetes.',
  "The shortest commercial flight in the world lasts only 57 seconds, connecting two islands in Scotland.",
  "Ants can lift objects 50 times their body weight.",
  "The Atlantic Ocean is getting wider by about 1 inch every year due to tectonic plate movement.",
  "Polar bears have black skin under their white fur to absorb heat.",
  "Giraffes have the same number of neck vertebrae as humans: 7.",
  "At around 5,000 to 7,000 degrees Celsius, Earth’s inner core is as hot as the surface of the Sun.",
  "The axolotl, a type of salamander, can regrow its limbs, heart, and even parts of its brain.",
  "The Olympic Games were originally played in the nude.",
  "The average golf ball has 336 dimples.",
  "A baseball has 108 stitches.",
  "Michael Phelps has more Olympic gold medals than 161 countries.",
  "The first World Cup was held in 1930 in Uruguay.",
  "The FIFA World Cup trophy weighs approximately 6.1 kg.",
  "Golf is the only sport to have been played on the moon.",
  "Cricket is the second most popular sport in the world.",
  "The first recorded baseball game was played in 1846.",
  "Table tennis balls can travel up to 112.7 km/h.",
  "The average lifespan of an MLB baseball is just 5-7 pitches.",
  "An NFL football is nicknamed \u201cThe Duke.\u201d",
  "The Tour de France is over 3,200 kilometers long.",
  "The fastest recorded speed in NASCAR is 342.82 km/h.",
  "The first basketball hoop was a peach basket.",
  "The marathon is named after the Battle of Marathon in 490 B.C.",
  "A professional soccer player runs an average of 11 kilometers per game.",
  "Badminton shuttlecocks can reach speeds of up to 322 km/h.",
  "Formula 1 drivers lose about 1.8 kilograms of body weight during a race.",
  "The Boston Marathon is the oldest annual marathon in the world.",
  "The first modern Olympic Games were held in Athens in 1896.",
  "The first baseball World Series was played in 1903.",
  "The longest cricket match lasted 14 days.",
  "Rugby was invented in 1823 during a game of soccer.",
  "The first Wimbledon tournament was held in 1877.",
  "The first professional basketball league was established in 1898.",
  "An Olympic gold medal is mostly silver.",
  "The average lifespan of a golf club is about 10,000 swings.",
  "The longest tennis match in history lasted 11 hours and 5 minutes.",
  "The fastest recorded tennis serve was 263.4 km/h.",
  "The heaviest sumo wrestler was reported to weigh 287 kg.",
  "The highest-scoring basketball game ended with a total score of 370 points.",
  "The first FIFA Women's World Cup was held in 1991.",
  'A first edition of Edgar Allan Poe\'s "Tamerlane and Other Poems" is one of the rarest books in American literature.',
  "J.K. Rowling was the first author to become a billionaire through book sales.",
  'The original title of Jane Austen\'s "Pride and Prejudice" was "First Impressions."',
  'Shakespeare invented over 1,700 words, including "bedroom," "eyeball," and "swagger."',
  "The Mona Lisa has no visible eyelashes or eyebrows.",
  'Mary Shelley was only 18 years old when she started writing "Frankenstein."',
  'The most expensive painting ever sold is Leonardo da Vinci\'s "Salvator Mundi," sold for $450.3 million.',
  "The Library of Alexandria was one of the largest libraries in the ancient world but was destroyed by fire.",
  "Agatha Christie is the best-selling fiction writer of all time, with over 2 billion copies sold.",
  'The word "robot" comes from the Czech word "robota," meaning "forced labor."',
  'The shortest poem in the English language is by poet Strickland Gillilan and reads: "Adam / Had \'em."',
  "Mark Twain was born and died during Halley's Comet appearances.",
  'The "Odyssey" and the "Iliad" were originally passed down orally before being written.',
  'Dante Alighieri wrote "The Divine Comedy" in Italian instead of Latin to make it accessible to a wider audience.',
  'The longest novel ever written is "In Search of Lost Time" by Marcel Proust, with over 1.2 million words.',
  'Oscar Wilde\'s only novel is "The Picture of Dorian Gray."',
  "The world's largest art museum is the Louvre in Paris.",
  'The name "Dracula" is derived from the Romanian word for "dragon" or "devil."',
  'Beatrix Potter initially self-published "The Tale of Peter Rabbit."',
  'Ernest Hemingway\'s famous six-word story is: "For sale: baby shoes, never worn."',
  'The painting "Starry Night" by Vincent van Gogh depicts the view from his asylum room.',
  "The ancient Romans used to send literary works to be copied onto papyrus scrolls in Egypt.",
  'J.R.R. Tolkien typed "The Hobbit" on a typewriter using only two fingers.',
  'The painting "The Persistence of Memory" by Salvador Dal\u00ed is famous for its melting clocks.',
  'The phrase "Big Brother is watching you" originates from George Orwell\'s novel "1984."',
  "Emily Dickinson published fewer than a dozen poems during her lifetime.",
  "Claude Monet painted a series of water lilies featuring more than 250 pieces.",
  '"Ulysses" by James Joyce is considered one of the most difficult books to read.',
  "The Louvre was originally a fortress before becoming a royal palace and then a museum.",
  'The longest play written by Shakespeare is "Hamlet."',
  'The first illustrated children\'s book was "Orbis Pictus" by John Amos Comenius.',
  'Stephen King wrote "Carrie" as his first published novel, which he initially threw in the trash.',
  "The Moai statues of Easter Island are considered both art and archaeological artifacts.",
  'The painting "Girl with a Pearl Earring" is often called the "Mona Lisa of the North."',
  "Moby-Dick was originally a commercial failure when it was first published in 1851.",
  '"Les Mis\u00e9rables" by Victor Hugo contains one of the longest sentences in literature, with 823 words.',
  "Leonardo da Vinci wrote his notes in mirror writing.",
  'George Bernard Shaw\'s play "Pygmalion" inspired the musical "My Fair Lady."',
  'The title of F. Scott Fitzgerald\'s "The Great Gatsby" was almost "Trimalchio in West Egg."',
  'Charles Dickens wrote "A Christmas Carol" in just six weeks.',
  'The world\'s oldest surviving printed book is the "Diamond Sutra," printed in 868 AD.',
  'Harper Lee published "To Kill a Mockingbird" in 1960, her only novel for 55 years until "Go Set a Watchman."',
  "The Sistine Chapel ceiling took Michelangelo four years to complete.",
];
