<script lang="ts">
    import { pickRandom } from "../dao";
    import { failEmojis } from "../dao/failEmojis";
    import { gifs } from "../dao/gifs";
    import { successEmojis } from "../dao/successEmojis";
    import { DisplayMode } from "./DisplayMode";
    import { preferencesStore } from "./preferencesStore";
    import Emoji from "./Emoji.svelte";
    import Gif from "./Gif.svelte";

    export let correct: boolean;

    $: emoji = pickRandom([...(correct ? successEmojis : failEmojis)]);
    $: gif = pickRandom(gifs.filter((g) => g.correct === correct));
</script>

<div class="flex w-full justify-center flex-col items-center relative">
    <div class="flex flex-row w-full justify-center">
        <div class="join rounded-b-none">
            <input
                class="join-item btn btn-xs"
                type="radio"
                name="displayMode"
                aria-label="Gif off"
                value={DisplayMode.Emoji}
                bind:group={$preferencesStore.displayMode}
            />
            <input
                class="join-item btn btn-xs"
                type="radio"
                name="displayMode"
                aria-label="Gif on"
                value={DisplayMode.Gif}
                bind:group={$preferencesStore.displayMode}
            />
        </div>
    </div>

    <div class="h-64 w-full flex justify-center">
        {#if $preferencesStore.displayMode === DisplayMode.Emoji}
            <Emoji symbol={emoji} class="text-8xl leading-[18rem]" />
        {:else}
            <div class="w-full h-full bg-base-content">
                <Gif {gif} />
            </div>
        {/if}
    </div>
</div>
