import App from "./App.svelte";
import { Amplify } from "aws-amplify";
import { resolveConfig } from "../config";
import { QuizApi } from "./QuizApi";
import { generateClient } from "aws-amplify/api";
import { ReteynSchema } from "../schema";
import { Analytics } from "analytics";
import { EventName, FirehoseAnalyticsPlugin, Tracker } from "../analytics";
import googleAnalytics from "@analytics/google-analytics";
import {
  BucketPicker,
  createOrderStrategies,
  defaultBucketPickerProps,
  defaultIntelligentStrategyProps,
  QuestionPicker,
} from "../question-picker/index.js";
import { QuizStatsResolver } from "./QuizStatsResolver";

const config = await resolveConfig();
Amplify.configure({
  ...config,
  Auth: {
    Cognito: {
      allowGuestAccess: true,
      identityPoolId: config.Auth?.Cognito.identityPoolId as string,
    },
  },
});
const client = generateClient<ReteynSchema>({
  authMode: "identityPool",
});

const picker = new QuestionPicker(
  createOrderStrategies(
    new BucketPicker(defaultBucketPickerProps),
    defaultIntelligentStrategyProps,
  ),
);
const quizStatsResolver = new QuizStatsResolver(picker);
const quizApi = new QuizApi(config, client);
const analytics = Analytics({
  app: "student-dashboard",
  version: 1,
  plugins: [
    googleAnalytics({
      measurementIds: [config.googleMeasurementId],
    }),
    new FirehoseAnalyticsPlugin({
      streamName: config.analyticsStreamName,
    }),
  ],
});
const tracker = new Tracker(analytics);

async function trackClick(target: EventTarget | null) {
  const elementTarget = target as HTMLElement | null;
  await tracker.track(EventName.Click, {
    innerText: elementTarget?.innerText,
    ariaLabel: elementTarget?.ariaLabel as string,
    tagName: elementTarget?.tagName,
  });
}

window.onunhandledrejection = (ev) =>
  tracker.track(EventName.Error, {
    message: ev.reason?.message,
    name: ev.reason?.name,
  });

window.onclick = (ev) => trackClick(ev.target);

tracker.page({
  // Exlude hash to avoid tokens being logged
  hash: "",
});

const app = new App({
  target: document.body,
  props: {
    quizStatsResolver,
    quizApi,
  },
});
export default app;
