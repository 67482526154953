import { Test } from "./Test.js";
import sortBy from "lodash/sortBy.js";
import { PickRequest } from "./PickRequest.js";
import { Question } from "./Question.js";
import flatten from "lodash/flatten.js";
import { Reteyner } from "./Reteyner.js";
import uniqueBy from "lodash/uniqBy.js";
import { Result } from "./Result.js";
import { QuestionOrderStrategy } from "./QuestionOrderStrategy.js";
import { QuestionOrder } from "../schema/index.js";

export class SequentialStrategy extends QuestionOrderStrategy {
  constructor() {
    super(QuestionOrder.Sequential);
  }

  apply(request: PickRequest): Result[] {
    return uniqueBy(
      request.scope
        .map((s) => ({
          question: this.nextQuestion(
            s.reteyner.topics,
            this.toLastTestedQuestionId(
              this.toApplicableTests({ ...request, scope: [s] }),
            ),
          ),
          contact: s.contact,
        }))
        .filter((v) => v.question) as Result[],
      (r) => r.question.id,
    );
  }

  nextQuestion(
    topics: Reteyner["topics"],
    questionId?: string,
  ): Question | undefined {
    const allQuestions = flatten(
      topics.map((t) =>
        t.questions.map((question) => ({
          question,
          topic: {
            index: t.index,
            id: t.id,
          },
        })),
      ),
    );
    const sortedQuestions = sortBy(
      allQuestions,
      (item) => item.topic.index,
      (item) => item.question.index,
    );
    const lastQuestionIndex = sortedQuestions.findIndex(
      (item) => item.question.id === questionId,
    );
    const nextItem =
      sortedQuestions[lastQuestionIndex + 1] || sortedQuestions[0];
    return nextItem?.question;
  }

  toLastTestedQuestionId(tests: Test[]): string | undefined {
    return sortBy(tests, (t) => this.toLastDate(t))
      .map((item) => item.questionId)
      .pop();
  }
}
