<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import shuffle from "lodash/shuffle.js";
    import { Question } from "./Question";
    import { sortItems } from "../dao";

    export let question: Question;
    let answers: Question["answers"];
    const dispatch = createEventDispatcher<{ answer: Question["answers"][number] }>();
    $: answers = question.random ? shuffle(question.answers) : sortItems(question.answers)
</script>

<div
    class="flex flex-col text-center items-center justify-center w-full full-height"
>
    <div class="flex flex-col w-4/5 gap-10 mt-4 justify-center">
        <p class="text-pretty font-bold">
            {question.text}
        </p>
        <div class="flex flex-col gap-3 text-left w-full">
            {#each answers as answer, index}
                {@const letter = String.fromCharCode(97 + index).toUpperCase()}
                <a
                    class="flex flex-row gap-4 bg-blue-100 rounded-lg px-3 py-3 cursor-pointer hover:bg-primary hover:text-primary-content w-full"
                    on:click={() => dispatch("answer", answer)}
                    href={null}
                >
                    <p class="hidden lg:block">
                        {letter})
                    </p>
                    <p class="w-full">
                        {answer.text}
                    </p>
                </a>
            {/each}
        </div>
    </div>
</div>
