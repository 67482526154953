import flattenDeep from "lodash/flattenDeep.js";
import { QuestionOrder } from "../schema/index.js";
import { PickRequest } from "./PickRequest.js";
import { Result } from "./Result.js";
import { Strategy } from "./Strategy.js";
import { Test } from "./Test.js";

export abstract class QuestionOrderStrategy implements Strategy {
  constructor(protected order: QuestionOrder) {}

  protected abstract apply(request: PickRequest): Result[];

  narrow(request: PickRequest): Result[] {
    const orderRequest = {
      ...request,
      scope: request.scope.filter(
        (v) => v.reteyner.questionOrder === this.order,
      ),
    };
    return this.apply({
      ...orderRequest,
      tests: this.toApplicableTests(orderRequest),
    });
  }

  toApplicableTests(request: PickRequest): Test[] {
    const questionIds = flattenDeep(
      request.scope.map((s) =>
        s.reteyner.topics.map((t) => t.questions.map((q) => q.id)),
      ),
    );
    return request.tests.filter(
      (t) =>
        this.toLastDate(t).getTime() < request.evaluationDate.getTime() &&
        questionIds.includes(t.questionId),
    );
  }

  toLastDate(test: Test): Date {
    return new Date(test.submission?.createdAt || test.createdAt);
  }
}
