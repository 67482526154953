import { IntelligentStrategy } from "./IntelligentStrategy.js";
import { IntelligentStrategyProps } from "./IntelligentStrategyProps.js";
import { BucketPicker } from "./BucketPicker.js";
import { SequentialStrategy } from "./SequentialStrategy.js";
import { Strategy } from "./Strategy.js";

export function createOrderStrategies(
  bucketPicker: BucketPicker,
  props: IntelligentStrategyProps,
): Strategy[] {
  return [
    new IntelligentStrategy(bucketPicker, props),
    new SequentialStrategy(),
  ];
}
