import orderBy from "lodash/orderBy.js";
import { History, QuestionPicker } from "../question-picker/index.js";
import flatten from "lodash/flatten.js";
import { toTruthy } from "../dao/index.js";
import flattenDeep from "lodash/flattenDeep.js";
import groupBy from "lodash/groupBy.js";
import sortBy from "lodash/sortBy.js";
import type { QuizStats } from "./QuizStats.js";

export class QuizStatsResolver {
  constructor(private picker: QuestionPicker) {}

  toQuizStats(history: History[], props: { testId: string }): QuizStats {
    const allTests = flatten(history.map((h) => h.tests));
    const test = allTests.find((t) => t.id === props.testId);
    if (test) {
      const reteyners = flattenDeep(history.map((h) => h.reteyners));
      const reteyner = reteyners.find((r) =>
        r.topics.find((t) => t.questions.find((q) => q.id === test.questionId)),
      );
      const siblingQuestions = flattenDeep(
        reteyner?.topics.map((t) => t.questions.map((q) => q.id)) || [],
      );
      const reteynerTests = allTests.filter((t) =>
        siblingQuestions.includes(t.questionId),
      );
      const previousTests = reteynerTests.filter(
        (t) => t.createdAt < test.createdAt,
      );
      const testsByQuestion = groupBy(previousTests, (t) => t.questionId);
      const latestSubmissions = toTruthy(
        flatten(
          Object.values(testsByQuestion).map((tests) =>
            orderBy(
              toTruthy(tests.map((t) => t.submission)),
              (s) => s.createdAt,
            ).pop(),
          ),
        ),
      );
      const previousTestsForThisQuestion = sortBy(
        testsByQuestion[test.questionId] || [],
        (t) => t.createdAt,
      );
      const previousSubmissionsForThisQuestion = toTruthy(
        previousTestsForThisQuestion.map((t) => t.submission),
      );
      const previousSubmission = previousSubmissionsForThisQuestion.pop();
      return {
        questions: siblingQuestions.length,
        distinctQuizzes: Object.keys(testsByQuestion).length,
        distinctSubmissions: latestSubmissions.length,
        distinctCorrectSubmissions: latestSubmissions.filter(
          (s) => s.answer.correct,
        ).length,
        previousAnswerSubmittedAt: previousSubmission?.createdAt,
        previousAnswerCorrect: previousSubmission?.answer?.correct,
      };
    }
    throw new Error("Test not present in history");
  }

  toNextQuestionId(
    history: History[],
    maximumBonusQuizzes: number,
  ): string | undefined {
    const allTests = orderBy(
      flatten(history.map((h) => h.tests)),
      (t) => t.createdAt,
    );
    const lastScheduledTest = allTests.filter((t) => !t.userRequested).pop();

    if (lastScheduledTest) {
      const bonusTests = allTests.filter(
        (t) => t.userRequested && t.createdAt > lastScheduledTest.createdAt,
      );
      if (bonusTests.length >= maximumBonusQuizzes) {
        return undefined;
      }
    }
    return this.picker.pick(history)?.question.id;
  }
}
