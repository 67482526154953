<script lang="ts">
    import { resolveHomeSite } from "../config";
    import { Logo } from "../reteyn-components/index.js";
    const homeSite = resolveHomeSite();
</script>

<div class="full-height w-full flex flex-col items-center bg-base-300">
    <div
        class="w-full max-w-lg grow flex flex-col items-center justify-center bg-base-100"
    >
        <slot />
    </div>
    <div class="footer max-w-lg footer-center px-4 py-4 bg-base-100">
        <div class="flex flex-row justify-between items-center w-full h-8">
            <a target="_blank" href={homeSite}>
                <Logo />
            </a>
            <div>
                <slot name="actions"/>
            </div>
        </div>
    </div>
</div>
