export enum CustomTypeName {
  ReportTest = "ReportTest",
  ReportStudent = "ReportStudent",
  ReportReteyner = "ReportReteyner",
  ReportTopic = "ReportTopic",
  ReportQuestion = "ReportQuestion",
  ReportAnswer = "ReportAnswer",
  Role = "Role",
  QuestionOrder = "QuestionOrder",
  EmailType = "EmailType",
}
