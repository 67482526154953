<script lang="ts">
    import { QuizStats } from "./QuizStats";

    export let answer: {correct: boolean};
    export let quizStats: QuizStats;

    function toFallbackMessage(answer: {correct: boolean}): string {
        return answer?.correct ? "Awesome! 🎉 You got that right! 👊" : "Oops! 🤕 No sweat, you'll get another go soon. 💪"
    }

    function toMessage(quizStats: QuizStats, answer: {correct: boolean}): string {
        const fallbackMessage = toFallbackMessage(answer);
        if (quizStats.previousAnswerSubmittedAt) {
            if (quizStats.previousAnswerCorrect && answer.correct) {
                return "Right again! 🔥 Clearly, it's mastery not luck! 🎓";
            }
            if (!quizStats.previousAnswerCorrect && answer.correct) {
                return "Nice work! 🎉 Great comeback on this quiz! 💪";
            }
        }
        return fallbackMessage;
        
    }
</script>
<p class="font-bold {$$props.class ?? ''} text-pretty">
    {toMessage(quizStats, answer)}
</p>