<script lang="ts">
    import { useLocation } from "svelte-navigator";
    import { Route } from "../config";

    const location = useLocation();
    
</script>

{#if location}
    {@const route = Object.values(Route).find(r => $location.pathname.startsWith(`/${r}/`))}
    <slot {route} location={$location}/>
{/if}
