<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { QuizApi } from "./QuizApi";
    import Loader from "./Loader.svelte";

    export let testId: string;
    export let answerId: string;
    export let api: QuizApi;

    let submissionPromise: Promise<boolean>

    const dispatch = createEventDispatcher<{submitted: void}>();

    $: submissionPromise = api.submit({testId, answerId}).then(() => dispatch("submitted"));

</script>
<Loader promise={submissionPromise}>
</Loader>