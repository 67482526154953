export function toArray<T>(input: Record<number, T>): (T | undefined)[] {
  return Object.entries(input).reduce(
    (array, [index, item]) => {
      const output = [...array];
      output[parseInt(index)] = item;
      return output;
    },
    [] as (T | undefined)[],
  );
}
