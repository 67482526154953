import type { V6Client } from "@aws-amplify/api-graphql";
import { ErrorMessage, type ReteynSchema } from "../schema/index.js";
import { parseTruthyResponse, toIterable, collect } from "../dao/index.js";
import { History, Test, testSelectionSet } from "../question-picker/index.js";
import { Config, magicLinkExpiryHours, toHistoryKey } from "../config/index.js";
import { writable, Writable } from "svelte/store";
import { questionSelectionSet } from "./questionSelectionSet.js";
import { Question } from "./Question.js";
import flatten from "lodash/flatten.js";

export class QuizApi {
  constructor(
    protected config: Config,
    protected client: V6Client<ReteynSchema>,
    protected historyByTestId: Record<
      string,
      Writable<Promise<History[]>>
    > = {},
    protected questionCache: Record<string, Promise<Question>> = {},
    protected processQueue: Promise<any>[] = [],
    protected readonly testIdSep = ":",
  ) {}

  toHistoryStore(request: { testId: string }): Writable<Promise<History[]>> {
    const originalTestId = this.toOriginalTestId(request);
    this.historyByTestId[originalTestId] =
      this.historyByTestId[originalTestId] ||
      writable(this.getHistory(request));
    return this.historyByTestId[originalTestId];
  }

  async question(request: { questionId: string }): Promise<Question> {
    this.questionCache[request.questionId] =
      this.questionCache[request.questionId] ||
      parseTruthyResponse(
        this.client.models.Question.get(
          { id: request.questionId },
          { selectionSet: questionSelectionSet },
        ),
      );
    return this.questionCache[request.questionId];
  }

  isOriginalTest(request: { testId: string }): boolean {
    return request.testId === this.toOriginalTestId(request);
  }

  toNextTestId(request: { testId: string; nextQuestionId: string }): string {
    return [this.toOriginalTestId(request), request.nextQuestionId].join(
      this.testIdSep,
    );
  }

  toOriginalTestId(request: { testId: string }): string {
    return request.testId.split(this.testIdSep).shift() as string;
  }

  async nextTest(request: {
    testId: string;
    nextQuestionId: string;
  }): Promise<{ testId: string }> {
    const nextTestId = this.toNextTestId(request);
    const baseParams = {
      id: nextTestId,
      questionId: request.nextQuestionId,
      userRequested: true,
    };
    const historyStore = this.toHistoryStore(request);
    historyStore.update(async (data) => {
      const history = await data;
      const contactId = this.toContactId(history, request.testId) as string;
      this.processQueue.push(
        parseTruthyResponse(
          this.client.models.Test.create({
            ...baseParams,
            contactId,
          }),
        ).catch((err) => historyStore.set(Promise.reject(err))),
      );
      const createdAt = new Date().toISOString();
      return this.mergeIntoHistory({
        history,
        tests: [
          {
            ...baseParams,
            createdAt,
          } as Test,
        ],
        contactId,
      });
    });
    return {
      testId: nextTestId,
    };
  }

  hasExpired(test: Test): boolean {
    const date = new Date();
    const latestSubmissionTime =
      new Date(test.createdAt).getTime() +
      magicLinkExpiryHours * 60 * 60 * 1000;
    return date.getTime() > latestSubmissionTime;
  }

  async createSubmission(request: {
    testId: string;
    answerId: string;
  }): Promise<ReteynSchema["Submission"]["type"]> {
    try {
      return await parseTruthyResponse(
        this.client.models.Submission.create({
          // Use the test ID for the submission so that we make sure it can't be submitted twice
          id: request.testId,
          testId: request.testId,
          answerId: request.answerId,
        }),
      );
    } catch (err) {
      if (this.isRecordExistsError(err)) {
        throw new Error(ErrorMessage.AlreadySubmitted);
      }
      throw err;
    }
  }

  toTest(history: History[], testId: string): Test | undefined {
    const allTests = flatten(history.map((h) => h.tests));
    return allTests.find((t) => t.id === testId);
  }

  toContactId(history: History[], testId: string): string | undefined {
    return history.find((h) => h.tests.find((t) => t.id === testId))?.contact
      .id;
  }

  async submit(request: {
    testId: string;
    answerId: string;
  }): Promise<ReteynSchema["Submission"]["type"]> {
    const historyStore = this.toHistoryStore(request);
    return new Promise((resolve, reject) => {
      historyStore.update(async (historyPromise) => {
        try {
          const history = await historyPromise;
          const test = this.toTest(history, request.testId);
          if (test) {
            if (this.hasExpired(test)) {
              throw new Error(ErrorMessage.Expired);
            }
            const question = await this.question({
              questionId: test.questionId,
            });
            const answer = question.answers.find(
              (a) => a.id === request.answerId,
            );
            if (answer) {
              this.processQueue.push(
                this.createSubmission(request)
                  .then((res) => resolve(res))
                  .catch((err) => {
                    reject(err);
                    historyStore.set(Promise.reject(err));
                  }),
              );
              return this.mergeIntoHistory({
                history,
                contactId: this.toContactId(history, request.testId) as string,
                tests: [
                  {
                    ...test,
                    submission: {
                      createdAt: new Date().toISOString(),
                      answer: {
                        id: answer.id,
                        correct: answer.correct,
                      },
                    },
                  },
                ],
              });
            }
          }
          throw new Error("Invalid answer");
        } catch (err) {
          reject(err);
          throw err;
        }
      });
    });
  }

  async getLatestTests(test: Test & { contactId: string }): Promise<Test[]> {
    const oldestPossibleUpdateTime =
      new Date().getTime() - magicLinkExpiryHours * 60 * 60 * 1000;
    return this.isOriginalTest({ testId: test.id })
      ? [test]
      : collect(
          toIterable((nextToken) =>
            this.client.models.Test.listTestByContactIdAndUpdatedAt(
              {
                contactId: test.contactId,
                updatedAt: {
                  gt: new Date(oldestPossibleUpdateTime).toISOString(),
                },
              },
              { nextToken, selectionSet: testSelectionSet },
            ),
          ),
        );
  }

  async getPreviousHistory(request: {
    contactId: string;
    testId: string;
  }): Promise<History[]> {
    const originalTestId = this.toOriginalTestId(request);
    const url = new URL(
      `https://${this.config.domains.recall}/${toHistoryKey(request)}?t=${originalTestId}`,
    );
    const response = await fetch(url);
    const emptyResponse = [
      {
        contact: {
          id: request.contactId,
        },
        tests: [],
        reteyners: [],
      },
    ];
    if (!response.ok) {
      return emptyResponse;
    }

    const historyData = (await response.json()) as History[];
    if (!historyData.length) {
      return emptyResponse;
    }
    return historyData;
  }

  mergeIntoHistory(request: {
    history: History[];
    tests: Test[];
    contactId: string;
  }): History[] {
    const { history, tests } = request;
    const entry = history.find((h) => h.contact.id === request.contactId);
    if (entry) {
      const latestIds = tests.map((t) => t.id);
      entry.tests = [
        ...entry.tests.filter((t) => !latestIds.includes(t.id)),
        ...tests,
      ];
    }
    return history;
  }

  async getHistory(request: { testId: string }): Promise<History[]> {
    const test = await parseTruthyResponse(
      this.client.models.Test.get(
        { id: request.testId },
        { selectionSet: [...testSelectionSet, "contactId"] },
      ),
    );
    if (this.hasExpired(test)) {
      throw new Error(ErrorMessage.Expired);
    }
    const [history, tests] = await Promise.all([
      this.getPreviousHistory({ ...request, contactId: test.contactId }),
      this.getLatestTests(test),
    ]);
    return this.mergeIntoHistory({
      contactId: test.contactId,
      history,
      tests,
    });
  }

  isRecordExistsError(input: any): boolean {
    return (
      (input as { errorType: string })?.errorType ===
      "DynamoDB:ConditionalCheckFailedException"
    );
  }
}
