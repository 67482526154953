<script lang="ts">
  import { Route as AppRoute, defaultMaximumBonusQuizzes } from "../config";
  import Home from "./Home.svelte";
  import { Router, Route, navigate } from "svelte-navigator";
  import Main from "./Main.svelte";
  import Confirmed from "./Confirmed.svelte";
  import QuizLoader from "./QuizLoader.svelte";
  import { QuizApi } from "./QuizApi";
  import Submitter from "./Submitter.svelte";
  import Action from "./Action.svelte";
  import NextQuestionButton from "./NextQuestionButton.svelte";
  import { QuizStatsResolver } from "./QuizStatsResolver";
  import { Subscribe } from "svelte-headless-table";
  import { termsStore } from "./termsStore";

  export let quizStatsResolver: QuizStatsResolver;
  export let quizApi: QuizApi;
  let loadingPromise: Promise<void> = Promise.resolve();

  async function nextQuestion(request: {
    testId: string;
    nextQuestionId: string;
  }) {
    const { testId } = await quizApi.nextTest(request);
    navigate(`/${AppRoute.Quiz}/${testId}`);
  }
</script>

<svelte:head>
  <style src="../reteyn-components/style.css"></style>
</svelte:head>

<Router>
  <Main>
    <Confirmed>
      <Route path="/{AppRoute.Submit}/:test/:answer" let:params>
        <Submitter
          api={quizApi}
          testId={params.test}
          answerId={params.answer}
          on:submitted={() => navigate(`/${AppRoute.Quiz}/${params.test}`)}
        />
      </Route>
      <Route path="/{AppRoute.Quiz}/:test" let:params>
        <QuizLoader {quizStatsResolver} testId={params.test} api={quizApi} />
      </Route>
    </Confirmed>

    <div slot="actions">
      {#if $termsStore.confirmed}
        <Action let:route let:location>
          {#await loadingPromise then}
            {#if route === AppRoute.Quiz}
              {@const [testId] = location.pathname
                .replace(`/${AppRoute.Quiz}/`, "")
                .split("/")}
              {#if testId}
                <Subscribe
                  historyData={quizApi.toHistoryStore({ testId })}
                  let:historyData
                >
                  {#await historyData then history}
                    {@const test = quizApi.toTest(history, testId)}
                    {#if test && !quizApi.hasExpired(test) && test.submission}
                      {#await quizApi.question({questionId: test.questionId}) then currectQuestion}
                        {@const nextQuestionId =
                          quizStatsResolver.toNextQuestionId(
                            history,
                            currectQuestion.topic.reteyner.organisation?.maximumBonusQuizzes || defaultMaximumBonusQuizzes,
                          )}
                        {#if nextQuestionId}
                          {#await quizApi.question( { questionId: nextQuestionId }, ) then question}
                            {#if question}
                              <NextQuestionButton
                                on:click={() =>
                                  (loadingPromise = nextQuestion({
                                    testId,
                                    nextQuestionId,
                                  }))}
                              />
                            {/if}
                          {/await}
                        {/if}
                      {/await}
                    {/if}
                  {/await}
                </Subscribe>
              {/if}
            {/if}
          {/await}
        </Action>
      {/if}
    </div>
  </Main>
  <Route path="/"><Home /></Route>
</Router>
