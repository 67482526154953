<script lang="ts">
    import { ErrorMessage } from "../schema";
    import { Loading } from "../reteyn-components";
    import LinkError from "./LinkError.svelte";
    import { magicLinkExpiryHours } from "../config";

    export let promise: Promise<any>;

    const knownErrors: Record<ErrorMessage, string> = {
        [ErrorMessage.AlreadySubmitted]:
            "Oops! 🤕 An answer has already been submitted for this quiz. 📮",
        [ErrorMessage.Expired]: `Oops! 🤕 Quizzes expire after ${magicLinkExpiryHours} hours. ⌛`,
    };

    const invalidLinkMessage = "Oops! 🤕 Something went wrong.";
    function toDisplayError(message: string): string {
        return knownErrors[message as ErrorMessage] || invalidLinkMessage;
    }
</script>

{#if promise}
    {#await promise}
        <Loading />
    {:then result}
        <slot {result}/>
    {:catch err}
        {@const message = toDisplayError(err.message)}
        <LinkError>{message}</LinkError>
    {/await}
{/if}