import sum from "lodash/sum.js";
import clamp from "lodash/clamp.js";
import { pickWeighted } from "./pickWeighted.js";
import { BucketPickerProps } from "./BucketPickerProps.js";

export class BucketPicker {
  constructor(private props: BucketPickerProps) {}

  toWeights(length: number): number[] {
    let previousSum = 0;
    const lastIndex = length - 1;
    return Array.from(new Array(length), () => this.props.initial).map(
      (value, index) => {
        const remaining = 1 - previousSum;
        const result = index
          ? index < lastIndex
            ? this.props.decay * remaining
            : remaining
          : value;
        previousSum += result;
        return result;
      },
    );
  }

  pick<T>(buckets: T[][]): T[] {
    const probs = this.toProbabilities(buckets);
    const bucketsWithProbs = buckets
      .map((item, index) => ({ item, probability: probs[index] }))
      .filter((e) => e.item.length);
    return pickWeighted(bucketsWithProbs) || [];
  }

  toProbabilities<T>(buckets: T[][]): number[] {
    const weights = this.toWeights(buckets.length);
    const occupiedSum = sum(
      buckets.map((items, index) => weights[index] * clamp(items.length, 0, 1)),
    );
    return buckets.map(
      (items, index) =>
        (clamp(items.length, 0, 1) * weights[index]) / occupiedSum,
    );
  }
}
