import { V6Client } from "@aws-amplify/api-graphql";
import { QuestionOrder, ReteynSchema } from "../schema/index.js";
import { AliasData } from "./AliasData.js";
import { aliasDataSelectionSet } from "./aliasDataSelectionSet.js";
import {
  collect,
  parseReturnValue,
  toIterable,
  toTruthy,
} from "../dao/index.js";
import { Reteyner } from "./Reteyner.js";
import flattenDeep from "lodash/flattenDeep.js";
import { testSelectionSet } from "./testSelectionSet.js";
import { Test } from "./Test.js";
import { History } from "./History.js";
import unique from "lodash/uniq.js";
import { reteynerSelectionSet } from "./reteynerSelectionSet.js";

export class HistoryDatasource {
  constructor(private client: V6Client<ReteynSchema>) {}

  toAliases(request: {
    email: string;
    organisationId: string;
  }): AsyncIterable<AliasData> {
    return toIterable((nextToken) =>
      this.client.models.Contact.listContactByEmailAndOrganisationId(
        {
          email: request.email,
          organisationId: { eq: request.organisationId },
        },
        {
          nextToken,
          selectionSet: aliasDataSelectionSet,
        },
      ),
    );
  }

  toReteynerIds(alias: AliasData): string[] {
    return unique(
      toTruthy(
        flattenDeep([
          ...alias.enrollments.map((e) => e.reteynerId),
          ...alias.groups.map((g) =>
            g.group.enrollments.map((e) => e.reteynerId),
          ),
        ]),
      ),
    );
  }

  async toReteyners(alias: AliasData): Promise<Reteyner[]> {
    const reteyners = await Promise.all(
      this.toReteynerIds(alias).map((id) =>
        parseReturnValue(
          this.client.models.Reteyner.get(
            { id },
            { selectionSet: reteynerSelectionSet },
          ),
        ),
      ),
    );
    return toTruthy(reteyners).map((r) => ({
      ...r,
      questionOrder: r.questionOrder || QuestionOrder.Intelligent,
    }));
  }

  toTests(contactId: string): AsyncIterable<Test> {
    return toIterable((nextToken) =>
      this.client.models.Test.listTestByContactIdAndUpdatedAt(
        { contactId },
        { selectionSet: testSelectionSet, nextToken },
      ),
    );
  }

  async *resolve(request: {
    email: string;
    organisationId: string;
  }): AsyncIterable<History> {
    for await (const alias of this.toAliases(request)) {
      const [reteyners, tests] = await Promise.all([
        this.toReteyners(alias),
        collect(this.toTests(alias.id)),
      ]);
      yield {
        contact: { id: alias.id },
        reteyners,
        tests,
      };
    }
  }
}
