<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { Chevron } from "../reteyn-components";

    const dispatch = createEventDispatcher<{ click: void }>();
</script>

<button class="btn btn-primary btn-sm" on:click={() => dispatch("click")}>
    Hit me again
    <div class="w-3 h-3">
        <Chevron class="fill-base-100 h-3 w-3 -rotate-90" />
    </div>
</button>
