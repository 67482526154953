import sortBy from "lodash/sortBy.js";
import { BucketPicker } from "./BucketPicker.js";
import { PickRequest } from "./PickRequest.js";
import { Test } from "./Test.js";
import flattenDeep from "lodash/flattenDeep.js";
import uniqueBy from "lodash/uniqBy.js";
import groupBy from "lodash/groupBy.js";
import { toArray } from "./toArray.js";
import { padArray } from "./padArray.js";
import { IntelligentStrategyProps } from "./IntelligentStrategyProps.js";
import { Result } from "./Result.js";
import { QuestionOrderStrategy } from "./QuestionOrderStrategy.js";
import { QuestionOrder } from "../schema/QuestionOrder.js";

export class IntelligentStrategy extends QuestionOrderStrategy {
  constructor(
    private bucketPicker: BucketPicker,
    private config: IntelligentStrategyProps,
  ) {
    super(QuestionOrder.Intelligent);
  }

  apply(request: PickRequest): Result[] {
    const buckets = this.toBuckets(request);
    return this.bucketPicker.pick(buckets);
  }

  threshold(evaluationDate: Date): Date {
    return new Date(evaluationDate.getTime() - this.config.testLockoutPeriodMs);
  }

  toBuckets(request: PickRequest): Result[][] {
    const options = uniqueBy(
      flattenDeep(
        request.scope.map((s) =>
          s.reteyner.topics.map((t) =>
            t.questions.map((question) => ({ question, contact: s.contact })),
          ),
        ),
      ),
      (q) => q.question.id,
    );
    const tests = this.toApplicableTests(request);
    const testsByQuestionId = groupBy(tests, (t) => t.questionId);
    const threshold = this.threshold(request.evaluationDate).getTime();
    const olderTestsByQuestion = Object.entries(testsByQuestionId).filter(
      ([_, tests]) =>
        tests.every((t) => this.toLastDate(t).getTime() < threshold),
    );
    const scoredOptions = olderTestsByQuestion
      .map(([questionId, tests]) => [
        this.toScore(tests),
        options.find((q) => q.question.id === questionId),
      ])
      .filter(([_, question]) => question);

    const resultGroups = toArray(
      groupBy(scoredOptions, ([score]) => score),
    ).map((items) => items?.map(([_, r]) => r) || []) as Result[][];
    const newQuestions = options.filter(
      (q) => !Object.keys(testsByQuestionId).includes(q.question.id),
    );
    return padArray(
      [newQuestions, ...resultGroups],
      this.config.buckets,
      [],
    ).map((v) => v || []);
  }

  toScore(tests: Test[]): number {
    return sortBy(tests, (t) => this.toLastDate(t).getTime()).reduce(
      (s, test) =>
        test.submission
          ? test.submission.answer.correct
            ? Math.min(s + 1, this.config.buckets - 2)
            : 0
          : s,
      0,
    );
  }
}
