import { pickRandom } from "../dao/index.js";
import flattenDeep from "lodash/flattenDeep.js";
import { Test } from "./Test.js";
import { Reteyner } from "./Reteyner.js";
import { Strategy } from "./Strategy.js";
import flatten from "lodash/flatten.js";
import uniqueBy from "lodash/uniqBy.js";
import { Question } from "./Question.js";
import { Result } from "./Result.js";
import { Contact } from "./Contact.js";
import groupBy from "lodash/groupBy.js";
import sortBy from "lodash/sortBy.js";
import shuffle from "lodash/shuffle.js";
import { History } from "./History.js";

export class QuestionPicker {
  constructor(private strategies: Strategy[]) {}

  toResult(
    items: { reteyner: Reteyner; contact: Contact }[],
    question: Question,
  ): Result | undefined {
    for (const { reteyner, contact } of items) {
      const questionIds = flattenDeep(
        reteyner.topics.map((t) => t.questions.map((q) => q.id)),
      );
      if (questionIds.includes(question.id)) {
        return {
          question,
          contact,
        };
      }
    }
  }

  pick(
    history: History[],
    evaluationDate: Date = new Date(),
  ): Result | undefined {
    const scope = uniqueBy(
      flatten(
        history.map((h) =>
          h.reteyners
            .filter((r) => r.enabled)
            .map((reteyner) => ({
              reteyner,
              contact: h.contact,
            })),
        ),
      ),
      (item) => item.reteyner.id,
    );
    const tests = flatten(history.map((h) => h.tests));
    const results = flatten(
      this.strategies.map((s) =>
        s.narrow({
          tests,
          scope,
          evaluationDate,
        }),
      ),
    );
    const topics = uniqueBy(
      flattenDeep(history.map((r) => r.reteyners.map((r) => r.topics))),
      (t) => t.id,
    );
    const fallbackDate = new Date(0);
    const resultsByTopicId = groupBy(
      results,
      (r) =>
        topics.find((t) => t.questions.find((q) => q.id === r.question.id))?.id,
    );
    const entries = Object.entries(resultsByTopicId)
      .map(([topicId, batch]) => [topics.find((t) => t.id === topicId), batch])
      .filter(([topic]) => topic)
      .map(([topic, batch]) => [
        this.toLastTested(topic as Reteyner["topics"][number], tests) ||
          fallbackDate,
        batch,
      ]) as [Date, Result[]][];
    const sortedBatches = sortBy(shuffle(entries), ([d]) => d.getTime());
    const earliestTested = sortedBatches.map(([_, batch]) => batch).shift();
    return pickRandom(earliestTested || []);
  }

  toLastTested(
    topic: Reteyner["topics"][number],
    tests: Test[],
  ): Date | undefined {
    const questionIds = topic.questions.map((q) => q.id);
    const associatedTests = tests.filter((t) =>
      questionIds.includes(t.questionId),
    );
    if (associatedTests.length) {
      return new Date(
        Math.max(
          ...associatedTests.map((t) => new Date(t.createdAt).getTime()),
        ),
      );
    }
  }

  toLastDate(test: Test): Date {
    return new Date(test.submission?.createdAt || test.createdAt);
  }
}
