<script lang="ts">
    import { Test } from "../question-picker";
    import { Question } from "./Question";
    import { QuizStats } from "./QuizStats";
    export let question: Question;
    export let quizStats: QuizStats;
    export let test: Test;

    function toCorrectCount(
        quizStats: QuizStats,
        test: Test,
    ): number {
        return (
            quizStats.distinctCorrectSubmissions +
            (quizStats.previousAnswerCorrect && !test.submission?.answer.correct
                ? -1
                : !quizStats.previousAnswerCorrect && test.submission?.answer.correct
                  ? 1
                  : 0)
        );
    }

    let totalCorrect: number;
    let mastery: number;
    $: totalCorrect =  toCorrectCount(quizStats, test)
    $: mastery = mastery = totalCorrect / quizStats.questions;
</script>


<div class="w-full flex flex-col gap-2">
    <div class="flex gap-4 wrap justify-between">
        <p class="font-bold">
            {question.topic.reteyner.name}
        </p>
        {#if quizStats.questions}
            <p>
                {#if totalCorrect >= quizStats.questions}🎓{:else}Mastery: {Math.min(
                        Math.round(100 * mastery),
                        100,
                    )}%{/if}
            </p>
        {/if}
    </div>
        <progress
            class="progress w-full progress-primary"
            value={totalCorrect}
            max={quizStats.questions}
        />
</div>
